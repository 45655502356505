import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getCoreRowModel, getPaginationRowModel, useReactTable, } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { Table as MuiTable } from '@mui/material';
import { useCallback, useMemo, useState, } from 'react';
import { useCallbackRef } from 'use-callback-ref';
import { ContainerColumn } from '@components/styled';
import LoadingIndicator from '@shared/ui/progress-loaders/LoadingIndicator';
import { IsDefined } from '@utils/js-ts';
import TableBody from './components/TableBody';
import TableBodyRow from './components/TableBodyRow';
import TableBodyCell from './components/TableBodyCell';
import CustomPagination from './components/Pagination';
import TableHeader from './components/TableHeader';
import TableHeaderRow from './components/TableHeaderRow';
import TableHeaderCell from './components/TableHeaderCell';
import NoDataOverlay from './components/NoDataOverlay';
const Container = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const TableContainer = styled((MuiTable))(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
const TableBodyContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 'auto',
    height: 'auto',
}));
const TableLoaderOverlay = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgb(255, 255, 255, 0.38)',
    zIndex: 1,
}));
const NoDataOverlayStyled = styled(NoDataOverlay)(() => ({
    minHeight: 377,
}));
const DefaultComponents = () => ({
    Header: TableHeader,
    HeaderRow: TableHeaderRow,
    HeaderCell: TableHeaderCell,
    Body: TableBody,
    BodyRow: TableBodyRow,
    BodyCell: TableBodyCell,
    NoDataOverlay: () => _jsx(NoDataOverlayStyled, {}),
});
const Table = ({ className = undefined, data, columnDef, rowsCount = -1, paginationState = undefined, onPaginationChange = undefined, getRowId = undefined, selectionState = {}, sortingState = [], onSortingChange = undefined, isLoading = false, components = {}, hideHeader = false, hideFooter = false, clientSideAutoPagination = false, onBodyRowHover = () => { }, loadingVariant = 'skeleton', defaultSkeletonRows = 5, skeletonStyles = {}, rowStyles = {}, expandable = false, }) => {
    const [expandedRowsState, updateExpandedState] = useState({});
    const handleExpand = (value) => {
        if (typeof value === 'function') {
            const updatedValue = value({});
            if (typeof updatedValue !== 'boolean') {
                updateExpandedState(updatedValue);
            }
        }
    };
    const tableOptions = useMemo(() => {
        const options = {
            data: isLoading ? Array(defaultSkeletonRows).fill({}) : data,
            columns: columnDef,
            getRowId,
            getCoreRowModel: getCoreRowModel(),
            state: {
                sorting: sortingState,
                rowSelection: selectionState,
                expanded: expandedRowsState,
            },
            onSortingChange,
            onExpandedChange: handleExpand,
            manualPagination: !clientSideAutoPagination, // false if not server-side
            manualSorting: true, // false if not server-side
            enableExpanding: expandable,
        };
        if (clientSideAutoPagination) {
            options.getPaginationRowModel = getPaginationRowModel();
        }
        else {
            options.pageCount = rowsCount;
            if (!options.state) {
                options.state = {};
            }
            options.state.pagination = paginationState;
            options.onPaginationChange = onPaginationChange;
        }
        return options;
    }, [
        data,
        columnDef,
        getRowId,
        clientSideAutoPagination,
        rowsCount,
        sortingState,
        onSortingChange,
        selectionState,
        paginationState,
        onPaginationChange,
        isLoading,
        defaultSkeletonRows,
        expandedRowsState,
        expandable,
    ]);
    const table = useReactTable(tableOptions);
    const Components = useMemo(() => (Object.assign(Object.assign({}, DefaultComponents()), components)), [components]);
    const paginationOnChange = useCallback((pageIndex) => {
        table.setPageIndex(pageIndex);
    }, [table]);
    const isPaginationEnabled = useMemo(() => clientSideAutoPagination || IsDefined(paginationState), [clientSideAutoPagination, paginationState]);
    const isTableEmpty = useMemo(() => !data.length && rowsCount <= 0, [data, rowsCount]);
    const showSkeleton = useMemo(() => isLoading && loadingVariant === 'skeleton', [isLoading, loadingVariant]);
    const showLoader = useMemo(() => isLoading && loadingVariant === 'loading-indicator', [isLoading, loadingVariant]);
    // NOTE: TableBody uses `tableContainerRef` inside the hook of useVirtual.
    // Values that are used from this hook are vital for the TableBody to work properly,
    // but because useRef does not trigger re-render when ref.current prop is changed,
    // as a current solution to force the re-render of TableBody component
    // we use callback that is triggered when the ref is set.
    const [, forceRerenderOnTableContainerRefSet] = useState(false);
    const tableContainerRef = useCallbackRef(null, () => forceRerenderOnTableContainerRefSet(true));
    return (_jsxs(Container, { className: className, ref: tableContainerRef, children: [_jsxs(TableContainer, { component: 'div', children: [!hideHeader && (_jsx(Components.Header, { tableApi: table, CellComponent: Components.HeaderCell, RowComponent: Components.HeaderRow })), _jsxs(TableBodyContainer, { children: [(!isTableEmpty || showSkeleton || showLoader) && (_jsxs(_Fragment, { children: [showLoader && (_jsx(TableLoaderOverlay, { children: _jsx(LoadingIndicator, { isLoading: true, takeFullContainerSpace: true }) })), _jsx(Components.Body, { tableApi: table, CellComponent: Components.BodyCell, RowComponent: Components.BodyRow, TableContainerRef: tableContainerRef, onRowHover: onBodyRowHover, isLoading: isLoading, expandable: expandable, defaultSkeletonRows: defaultSkeletonRows, skeletonStyles: skeletonStyles, rowStyles: rowStyles })] })), isTableEmpty && !isLoading && (_jsx(Components.NoDataOverlay, {}))] })] }), !hideFooter &&
                !isTableEmpty &&
                isPaginationEnabled && (_jsx(CustomPagination, { pageIndex: table.getState().pagination.pageIndex, pageSize: table.getState().pagination.pageSize, totalRowsCount: rowsCount, onChange: paginationOnChange }))] }));
};
export default Table;
